<template>
  <div>
    <sub-header :backButton="true" :backRedirectionUrl="`/sales-orders`"></sub-header>

    <!-- order detail forms -->

    <div class="grid gap-7 mt-2">
      <!-- Order details -->
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Order Details
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.clientsList"
        >
          <Loader />
        </div>
        <FormulateForm v-else>
          <div class="grid grid-cols-3 gap-x-6 p-2 ml-2">
            <div class="flex w-full">
              <!-- Client Name -->
              <div class="w-10/12">
                <h2 class="mb-2 mt-1">Client Name *</h2>
                <Dropdown
                  searchable
                  placeholder="Client name"
                  :class="{'disableDropdown' : (disableMethod || disableFields)}"
                  :options="clientsList"
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  v-model="orderDetails.client_name"
                  @input="handleClientSelect(orderDetails.client_name)"
                  @select="onClientSelected"
                  :config="{ label: 'name', trackBy: 'name' }"
                  maxWidth="100%"
                  minWidth="100%"
                  width="102px"
                  :disabled="disableMethod || disableFields"
                />
              </div>
              <!-- Currency code (symbol) -->
              <div class="flex w-2/12 items-center justify-end mt-1">
                <div
                  class="flex h-11 bg-gray-100 w-3/4 rounded-lg items-center justify-center"
                >
                  <div class="p-2">{{ currencySymbol }}</div>
                </div>
              </div>
            </div>

            <FormulateInput
              class="w-full disableInput"
              label="Order Id"
              placeholder="order id"
              type="text"
              v-model="orderDetails.order_number"
              disabled
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
            <FormulateInput
              class="w-full"
              :class="{'disableInput' : (disableMethod || disableFields)}"
              label="Tax Number (VAT, GSTIN etc..)"
              placeholder="tax number"
              type="text"
              :disabled="disableMethod || disableFields"
              v-model="orderDetails.tax_number"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
            <label>Order Date <span class="text-red-500 text-lg">*</span>
              <input
                class="w-full input input-bordered orderDate"
                label="Order Date"
                validation="required"
                placeholder="date"
                type="text"
                v-model="orderDetails.date"
                Disabled
              />
            </label>
            <div>
              <h2 class="mb-2 mt-1">Payment Term</h2>
              <Dropdown
                :disabled="disableMethod"
                :class="{'disableDropdown' : disableMethod}"
                placeholder="Payment Terms"
                :options="paymentTerms"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                v-model="orderDetails.payment_term"
                :config="{ label: 'name', trackBy: 'name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <div>
              <h2 class="mb-2 mt-1">Order Reference</h2>
              <Dropdown
                disabled
                placeholder="Order refrence"
                :options="orderReferences"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                v-model="orderDetails.order_reference"
                :config="{ label: 'name', trackBy: 'name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
                class="disableDropdown"
              />
            </div>
          </div>
        </FormulateForm>
      </div>
      <!-- Order details ends -->

      <!-- Pack Details -->
      <div
        class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col"
        v-if="orderDetails.client_name"
      >
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Pack Details

          <svg
            v-if="creditPacksList.length > 0 && !disableMethod"
            @click="addPack"
            class="cursor-pointer"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1758_9116)">
              <path
                d="M3.41391 8.69432C3.03391 8.31088 3.03391 7.68963 3.41391 7.3065C3.59641 7.12275 3.84391 7.01931 4.10172 7.01931H11.8892C12.4267 7.00994 12.8698 7.4415 12.8792 7.98338C12.8886 8.52525 12.4605 8.97213 11.923 8.9815C11.9117 8.9815 11.9005 8.9815 11.8892 8.9815H4.10203C3.84391 8.98244 3.59609 8.879 3.41391 8.69432Z"
                fill="#0D69D5"
              />
              <path
                d="M7.31921 12.6994C7.13921 12.5125 7.03827 12.2584 7.03921 11.9937V4.00717C7.03014 3.45592 7.45077 3.00154 7.97921 2.99186C8.50764 2.98217 8.94327 3.42123 8.95264 3.97248C8.95264 3.98404 8.95264 3.99561 8.95264 4.00717V11.9937C8.95264 12.545 8.52421 12.9919 7.99577 12.9915C7.74202 12.9915 7.49889 12.8865 7.31952 12.6994H7.31921Z"
                fill="#0D69D5"
              />
              <path
                d="M11.614 16H10.5625C10.1693 16 9.85059 15.6812 9.85059 15.2881C9.85059 14.895 10.1693 14.5763 10.5625 14.5763H11.614C12.4012 14.5763 13.144 14.2669 13.7056 13.7053C14.2671 13.1438 14.5765 12.4009 14.5765 11.6138V4.38594C14.5765 3.59875 14.2671 2.85594 13.7056 2.29437C13.144 1.73281 12.4012 1.42344 11.614 1.42344H10.5625C10.1693 1.42344 9.85059 1.10469 9.85059 0.711563C9.85059 0.318438 10.169 0 10.5621 0H11.6137C14.0321 0 15.9996 1.9675 15.9996 4.38594V11.6138C15.9996 14.0322 14.0321 15.9997 11.6137 15.9997L11.614 16Z"
                fill="#0D69D5"
              />
              <path
                d="M5.43781 16H4.38625C1.9675 16 0 14.0325 0 11.6141V4.38594C0 1.9675 1.9675 0 4.38594 0H5.4375C5.83063 0 6.14937 0.31875 6.14937 0.711875C6.14937 1.105 5.83063 1.42375 5.4375 1.42375H4.38594C3.59875 1.42375 2.85594 1.73312 2.29437 2.29469C1.73281 2.85625 1.42344 3.59906 1.42344 4.38625V11.6141C1.42344 12.4012 1.73281 13.1441 2.29437 13.7056C2.85594 14.2672 3.59875 14.5766 4.38594 14.5766H5.4375C5.83063 14.5766 6.14937 14.8953 6.14937 15.2884C6.14937 15.6816 5.83063 16.0003 5.4375 16.0003L5.43781 16Z"
                fill="#0D69D5"
              />
            </g>
            <defs>
              <clipPath id="clip0_1758_9116">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.packDetails"
        >
          <Loader />
        </div>
        <div v-else>
          <custom-table
            @discountchange="onDiscountChange"
            @noteEditor="onEditorChange"
            :columns="columns"
            :rows="rows"
            class="p-2"
            showSubTotal
            :subTotalFields="subTotalFields"
            :customClass="customClass"
            :currencySymbol="currencySymbol"
            :totalAmount="convertIntoTwoDecimal(totalAmount)"
            :notes="orderDetails.note"
            emptyTableMessage="No credit pack alloted to tenant."
            userType="tenant_user"
            :orderTable="true"
            :disabledNote="checkNoteDisable"
          >
            <template v-slot:pack_name="props">
              <div class="justify-center ml-3">
                <Dropdown
                  :disabled="disableMethod"
                  :class="{'disableDropdown' : disableMethod}"
                  placeholder="Credit Packs"
                  :options="creditPacksList"
                  searchable
                  reposition
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  v-model="props.item.name"
                  @input="handleDiscount(props.item)"
                  :config="{ label: 'name', trackBy: 'name' }"
                  maxWidth="93%"
                  minWidth="93%"
                  width="200px"
                />
              </div>
            </template>
            <template v-slot:available_packs="props">
              <div class="justify-center">
                <input
                  disabled
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.available_packs"
                />
              </div>
            </template>
            <template v-slot:quantity="props">
              <div class="justify-center">
                <input
                  :disabled="disableMethod"
                  type="number"
                  class="input input-bordered input-sm w-1/2 removeArrowKey"
                  v-model="props.item.quantity"
                  @input="handleDiscountedPrice(props.item)"
                  @keypress="isIntegerNumber($event)"
                />
              </div>
            </template>
            <template v-slot:unit_price="props">
              <label class="input-group input-group-xs flex justify-center">
                <span>{{ currencySymbol }}</span>
                <input
                  type="text"
                  class="input input-bordered input-sm w-full"
                  v-model="props.item.unit_price"
                  disabled
                />
              </label>
            </template>
            <template v-slot:discount="props">
              <label class="input-group input-group-sm flex justify-center">
                <span>%</span>
                <input
                  :disabled="disableMethod"
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.discount"
                  @input="handleDiscountedPrice(props.item)"
                />
                <!-- <span v-if="hasDiscountError" class="text-red-700 text-sm"
                  >Invalid Value</span
                > -->
              </label>
              <span v-if="props.item.hasDiscountError" class="text-red-700 text-sm">Invalid Value</span>
            </template>
            <template v-slot:discounted_price="props">
              <label class="input-group input-group-sm flex justify-center">
                <span>{{ currencySymbol }}</span>
                <input
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.discounted_price"
                  disabled
                />
              </label>
            </template>
            <template v-slot:action="props">
              <svg
                @click="removeRow(props.item)"
                class="justify-center cursor-pointer ml-16"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!disableMethod"
              >
                <path
                  d="M3.62645 4.87203V13.2127C3.62645 14.1998 4.48363 15 5.54033 15H11.2824C12.3395 15 13.1967 14.1998 13.1967 13.2127V4.87203H3.62645ZM6.18072 13.1217C6.18072 13.3385 6.03751 13.514 5.86074 13.514H5.54034C5.36336 13.514 5.22016 13.3385 5.22016 13.1217L5.22016 6.4518C5.22016 6.24277 5.35451 6.07583 5.52225 6.06362H5.87823C6.04597 6.07583 6.18032 6.24278 6.18032 6.4518V13.1217H6.18072V13.1217ZM9.05172 13.1217C9.05172 13.3385 8.90853 13.514 8.73173 13.514H8.09117C7.9146 13.514 7.77119 13.3385 7.77119 13.1217V6.4518C7.77119 6.24277 7.90557 6.07583 8.07328 6.06362H8.74926C8.91721 6.07583 9.05134 6.24278 9.05134 6.4518V13.1217H9.05172V13.1217ZM11.6028 6.4518V13.1218C11.6028 13.3387 11.4596 13.5141 11.2824 13.5141H10.9622C10.7856 13.5141 10.6424 13.3387 10.6424 13.1218V6.4518C10.6424 6.24277 10.7766 6.07583 10.9445 6.06362H11.3003C11.4686 6.07583 11.6028 6.24277 11.6028 6.4518ZM13.1967 2.48925H3.62645C3.27408 2.48925 2.98828 2.75611 2.98828 3.08494V3.38279C2.98828 3.71199 3.27408 3.9785 3.62645 3.9785H13.1967C13.5488 3.9785 13.8346 3.71199 13.8346 3.38279V3.08494C13.8346 2.75611 13.5492 2.48925 13.1967 2.48925Z"
                  fill="#FF0000"
                />
                <path
                  d="M7.65891 1H9.27774C9.63032 1 9.91572 1.26687 9.91572 1.5957V1.89354C9.91572 2.22275 9.62992 2.48925 9.27774 2.48925H7.65891C7.30655 2.48925 7.02075 2.22275 7.02075 1.89354V1.5957C7.02075 1.26687 7.30655 1 7.65891 1Z"
                  fill="#FF0000"
                />
              </svg>
            </template>
          </custom-table>
        </div>
      </div>
      <!-- Pack Details ends -->
    </div>
    <!-- Order detail form ends -->
    <div
      class="flex gap-3 float-right"
      v-if="
        (orderDetails?.status == 'draft' || !orderDetails?.status) &&
        !orderStatusDone
      "
    >
      <Button
        :loader="loadingState.draftOrder"
        text="Save in Draft"
        class="mt-2"
        type="primary"
        @click="handleCreateOrders(true)"
        :disabled="rows.length > 0 && orderDetails.client_name ? false : true"
      />
      <Button
        :loader="loadingState.submitOrder"
        text="Submit Order"
        class="mt-2"
        type="primary"
        @click="handleCreateOrders(false)"
        :disabled="rows.length > 0 && orderDetails.client_name ? false : true"
      />
    </div>
    <div
      class="flex gap-3 float-right"
      v-if="orderDetails?.status == 'pending approval' && !orderStatusDone"
    >
      <Button
        text="Reject"
        class="mt-2"
        type="primary"
        @click="changeOrderStatus('rejected')"
        :disabled="rows.length > 0 && orderDetails.client_name ? false : true"
        :loader="loadingState.rejectOrder"
      />
      <Button
        text="Approve"
        class="mt-2"
        type="primary"
        @click="changeOrderStatus('approved')"
        :disabled="rows.length > 0 && orderDetails.client_name ? false : true"
        :loader="loadingState.approveOrder"
      />
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import customTable from "@shared/components/custom-table";
import Dropdown from "@shared/components/dropdown-base";
import { checkDiscount, isIntegerNumber, FormatDateNew } from "../../../plugins/functions";

import {
  getClientsList,
  getClientTax,
  createOrder,
  getClientCreditPacks,
  getOrderNumber,
  getOrderDetails,
  getOrderCreditPacks,
  orderStatus,
  updateOrder,
  getClientDetails,
  getCurrencyDetail,
  getSelectedClientDetail,
} from "../services";
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import { mapGetters } from "vuex";

export default {
  name: "createOrders",
  data: () => {
    return {
      orderStatusDone: false,
      paymentTerms: [
        { name: "Due on receipt", value: '"due on receipt"' },
        { name: "Due 15 days", value: "due 15 days" },
        { name: "Due 30 days", value: "due 30 days" },
        { name: "Due 45 days", value: "due 45 days" },
        { name: "Due 60 days", value: "due 60 days" },
      ],
      orderReferences: [{ name: "Internal" }],
      showSubTotal: false,
      loadingState: {
        clientsList: false,
        packDetails: false,
        submitOrder: false,
        draftOrder: false,
        approveOrder: false,
        rejectOrder: false,
      },
      columns: [
        {
          name: "Pack Name",
          label: "pack_name",
          type: "custom",
        },
        {
          name: "Available",
          label: "available_packs",
          type: "custom",
        },
        {
          name: "Quantity",
          label: "quantity",
          type: "custom",
        },
        {
          name: "Unit Price",
          label: "unit_price",
          type: "custom",
        },
        {
          name: "Discount",
          label: "discount",
          type: "custom",
        },
        {
          name: "Discounted Price",
          label: "discounted_price",
          type: "custom",
        },
        {
          name: "Action",
          label: "action",
          type: "custom",
        },
      ],
      rows: [],
      subTotalFields: [
        { name: "SUB TOTAL", percent: "-", value: 0 },
        // { name: "Corporate Discount", percent: 5, value: 0 },
      ],
      orderDetails: {
        client_name: null,
        order_number: null,
        tax_number: null,
        order_reference: "Internal",
        payment_term: 'due 30 days',
        date: FormatDateNew(new Date()),
        note: "",
      },
      creditPacksList: [],
      clientsList: [],
      customClass: {
        headers: "px-11",
      },
      selectedClientTaxes: [],
      totalAmount: null,
      subTotal: null,
      orderId: null,
      status: "",
      selectedPacks: [],
      currencySymbol: "",
      currencyDecimalPlace: 2,
      hasDiscountError: false,
      checkNoteDisable: false,
      validDiscountValue: 0
    };
  },
  computed: {
    ...mapGetters(["getTenantId"]),
    disableMethod() {
      if (this.orderDetails?.status == "approved") {
        return true;
      } else if (this.orderDetails?.status == "rejected") {
        return true;
      } else if (this.orderDetails?.status == "pending approval") {
        return true;
      } else {
        return false;
      }
    },
    disableFields() {
      if (this.orderDetails?.status == "draft") {
        return true;
      } else {
        return false;
      }
    },
    editMode() {
      return this.$route.params.id ? true : false;
    },
  },
  async mounted() {
    this.loadingState.clientsList = true;
    if (this.$route.params.id) {
      await this.handleOrderDetails();
    } else {
      await this.setClientsList();
    }
    this.loadingState.clientsList = false;
  },
  components: {
    SubHeader,
    customTable,
    Dropdown,
    Button,
    Loader,
  },
  methods: {
    isIntegerNumber,
    checkDiscount,
    FormatDateNew,
    onEditorChange(value) {
      this.orderDetails.note = value;
    },
    async handleOrderDetails() {
      try {
        this.checkNoteDisable = true
        this.loadingState.packDetails = true;
        const orderDetails = await getOrderDetails(this.$route.params.id);
        this.orderDetails = orderDetails.data;
        this.orderDetails = {
          date: FormatDateNew(new Date(orderDetails.data.created_at)),
          ...this.orderDetails,
        };
        this.currencySymbol = orderDetails.data.currency_symbol;
        if (this.orderDetails.status == 'draft') {
          this.checkNoteDisable = false
          let allOrderPackDetails = await getClientCreditPacks(this.$route.params.client_id)
          allOrderPackDetails.data.forEach((res) => {
          this.creditPacksList.push({
            name: res.name,
            amount: res.unit_price,
          });
        });
        }
        let orderPackDetails = await getOrderCreditPacks(this.$route.params.id);
        // orderPackDetails.data.forEach((res) => {
        //   this.creditPacksList.push({
        //     name: res.name,
        //     amount: res.unit_price,
        //   });
        // });
        const clientData = await getClientDetails(this.$route.params.client_id);
        this.fetchClientDetailAndSetCurrency(this.$route.params.client_id);
        this.orderDetails.client_name = clientData.data.name;
        orderPackDetails.data?.forEach(odrData => {
          odrData.unit_price = this.convertIntoTwoDecimal(odrData.unit_price);
          odrData.discounted_price = this.convertIntoTwoDecimal(odrData.discounted_price);
        })
        this.rows = orderPackDetails.data;
        await this.calculateSubTotal(this.$route.params.client_id);
        this.handleTotal();
        this.loadingState.packDetails = false;
      } catch (err) {
        this.$toast.error(
          err?.response?.data?.detail || "Unable to fetch the details!"
        );
        this.loadingState.packDetails = false;
      }
    },
    onDiscountChange(value, index) {
      const percentageValue = parseFloat(value);
      if (percentageValue !== "" && percentageValue < 0) {
        this.$toast.error("value must be in positive number.");
        return;
      }
      this.subTotalFields[index] = {
        ...this.subTotalFields[index],
        percent: percentageValue,
      };
      this.corpDiscount = value;
      this.discountCalculation();
    },
    handleTotal() {
      this.discountCalculation();
    },

    async calculateSubTotal(client_id) {
      this.selectedClientTaxes = [];
      // const data = [];
      const { data } = await getClientTax(client_id);
      this.subTotalFields = [
        { name: "SUB TOTAL", percent: "-", value: 0 },
        // { name: "Corporate Discount", percent: 5, value: 0 },
      ];
      if (data) {
        data.forEach((res) => {
          this.selectedClientTaxes.push({
            name: res.tax_name,
            rate: res.rate,
          });
          this.subTotalFields.push({
            name: res.tax_name,
            percent: res.rate,
            value: 0,
          });
        });
      }
      this.discountCalculation();
    },
    async setClientsList() {
      try {
        let { data } = await getClientsList(this.getTenantId);
        this.orderDetails.date = FormatDateNew(new Date());
        const clientKeys = ["id", "name", "tax_number", "currency_id"];
        data = data.map((obj) => {
          const newObj = {};
          clientKeys.forEach((res) => {
            newObj[res] = obj[res];
          });
          return newObj;
        });
        this.clientsList = data;
      } catch (err) {
        this.$toast.error(
          err?.response?.data?.detail || "Unable to fetch the client list!"
        );
      }
    },
    addPack() {
      this.rows.push({
        row_id: this.rows.length + 1,
        name: "",
        available_packs: 0,
        quantity: 1,
        unit_price: this.convertIntoTwoDecimal(0),
        discount: 0,
        discounted_price: this.convertIntoTwoDecimal(0),
        action: true,
        id: "",
      });
    },
    async handleClientSelect(value) {
      try {
        this.loadingState.packDetails = true;
        this.rows = [];
        this.orderDetails.tax_number = value.tax_number;
        const creditResponse = await getClientCreditPacks(value.id);
        this.creditPacksList = [];
        creditResponse.data.forEach((res) => {
          this.creditPacksList.push({
            name: res.name,
            amount: res.unit_price,
            id: res.id,
            currency_id: res.currency_id,
            discount: res.discount,
            available_packs: res.available_packs,
          });
        });
        if (this.creditPacksList.length > 0) {
          this.addPack();
        }
        const orderResponse = await getOrderNumber(value.id);
        this.orderDetails.order_number = orderResponse.data.order_number;
        this.orderId = orderResponse.data.order_id;
        await this.calculateSubTotal(value.id);
        this.loadingState.packDetails = false;
        if (this.rows.length > 0) {
          this.showSubTotal = true;
        } else {
          this.showSubTotal = false;
        }
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || "Unable to fetch the details"
        );
        this.loadingState.packDetails = false;
      }
    },
    handleDiscount(value) {
      value.unit_price = this.convertIntoTwoDecimal(value.name.amount);
      value.id = value.name.id;
      value.currency_id = value.name.currency_id;
      value.discount = value.name.discount;
      value.available_packs = value.name.available_packs;
      value.name = value.name.name;
      this.handleDiscountedPrice(value);
    },
    handleDiscountedPrice(value) {
      if(value.discount > 100) {
        value.discount = value.validDiscountValue
      } else if (value.discount <= 100) {
        value.validDiscountValue = value.discount
        if (value.discount && !checkDiscount(value.discount, this.currencyDecimalPlace)) {
          this.hasDiscountError = true;
          value.hasDiscountError = true;
        } else {
          this.hasDiscountError = false;
          value.hasDiscountError = false;
        }
        if (!this.hasDiscountError) {
          const discount = this.convertIntoTwoDecimal(
            (value.unit_price * value.discount) / 100
          );
          value.discounted_price = this.convertIntoTwoDecimal(
            (value.unit_price - discount) * value.quantity
          );
          this.discountCalculation();
        }
      }
    },
    removeRow(value) {
      this.rows = this.rows.filter((res) => res.row_id !== value.row_id);
      this.handleTotal();
    },
    async handleCreateOrders(drafted) {
      try {
        let creditPackSelected = true;
        let creditPackDuplicated = false;
        let invalidQuantity = false;
        let isNegativeQuantity = false;
        let selectedPacks = [];
        this.rows.forEach((res) => {
          if (selectedPacks.includes(res.name)) {
            creditPackDuplicated = true;
          }
          selectedPacks.push(res.name);
          if (!res.name) {
            creditPackSelected = false;
          }
          if (parseInt(res.quantity) > parseInt(res.available_packs)) {
            invalidQuantity = true;
          }
          if (parseInt(res.quantity) <= 0) {
            isNegativeQuantity = true;
          }
        });
        if (!this.orderDetails?.payment_term) {
          this.$toast.error("Please select the payment terms.");
          return;
        }
        if (!creditPackSelected) {
          this.$toast.error("Please select credit pack.");
          return;
        }
        if (isNegativeQuantity) {
          this.$toast.error("Quantity should be greater then 0.");
          return;
        }
        if (invalidQuantity) {
          this.$toast.error("Quantity can not be greater then available packs.");
          return;
        }
        if (creditPackDuplicated) {
          this.$toast.error("Credit Packs cannot be duplicated.");
          return;
        }
        if (!this.orderDetails.order_reference) {
          this.$toast.error("Please select the order reference.");
          return;
        }
        if (this.hasDiscountError) {
          this.$toast.error("Invalid discount error.");
          return;
        }
        if (drafted) {
          this.loadingState.draftOrder = true;
        } else {
          this.loadingState.submitOrder = true;
        }
        let taxes = [];
        this.selectedClientTaxes.forEach((res) => {
          taxes.push({
            name: res.name,
            rate: res.rate,
          });
        });

        let creditPack = [];
        this.rows.forEach((res) => {
          creditPack.push({
            name: res.name,
            quantity: res.quantity,
            unit_price: res.unit_price,
            discount: res.discount,
            discounted_price: res.discounted_price,
            id: res.id,
            currency_id: res.currency_id,
          });
        });
        let payload = {
          order_reference: this.orderDetails.order_reference?.name
            ? this.orderDetails.order_reference.name
            : this.orderDetails.order_reference,
          payment_term:
            this.orderDetails?.payment_term?.value ||
            this.orderDetails?.payment_term ||
            "",
          sub_total: this.subTotal,
          discount: this.corpDiscount || 0.0,
          taxes: taxes, // Change this when manage tax changes are done.
          draft: drafted,
          note: this.orderDetails.note ? this.orderDetails.note : "",
          order_number: this.orderDetails.order_number,
          order_id: this.orderId,
          client_id: this.orderDetails.client_name.id,
          credit_packs: creditPack,
          total_amount: parseFloat(this.totalAmount),
        };
        if (this.$route.params.id) {
          this.updateOrders(payload);
        } else {
          this.createOrder(payload, drafted);
        }
      } catch (err) {
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
        if (err?.response.status == 400) {
          this.$toast.error(err.response.data.detail);
        } else {
          this.$toast.error("Something went wrong!");
        }
      }
    },
    async createOrder(payload, drafted) {
      try {
        await createOrder(payload);
        this.$toast.success(
          drafted
            ? "Order drafted successfully"
            : "Order submitted successfully."
        );
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
        this.$router.push({
          path: "/sales-orders",
        });
      } catch (error) {
        this.$toast.error(
          error.response.data.detail || "Unable to create order!"
        );
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
      }
    },
    async updateOrders(payload) {
      let updatedPayload = {
        ...payload,
        order_id: this.$route.params.id,
        tenant_id: this.$route.params.tenant_id,
      };
      try {
        await updateOrder(updatedPayload, this.$route.params.id)
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
        this.$router.push({
          path: "/sales-orders",
        });
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || "Unable to update order!"
        );
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
      }
    },
    async changeOrderStatus(status) {
      if (status == "approved") {
        this.loadingState.approveOrder = true;
      } else {
        this.loadingState.rejectOrder = true;
      }
      try {
        let { data } = await orderStatus({ status: status }, this.$route.params.id);
        this.orderStatusDone = true;
        this.$toast.success(data?.message ? data?.message : "Status updated successfully.");
        this.$router.push({
          path: "/sales-orders",
        });
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || "Unable to change order status!"
        );
        this.loadingState.approveOrder = false;
        this.loadingState.rejectOrder = false;
      }
      // await orderStatus({ status: status }, this.$route.params.id);
    },
    // calculation the total amount and sub total field amount
    discountCalculation() {
      let subTotal = 0;
      let discounts = 0;
      this.subTotal = 0;
      let totalTaxPercentage = [];

      this.rows.forEach((row) => {
        subTotal += parseFloat(row.discounted_price);
      });

      this.subTotal = subTotal;

      this.subTotalFields.forEach((res) => {
        if (res.name == "SUB TOTAL") {
          res.value = this.convertIntoTwoDecimal(subTotal);
        } else if (res.name == "Corporate Discount") {
          res.percent = this.corpDiscount ? this.corpDiscount : 0;
          const percentage = parseFloat(res.percent);
          const discount = this.convertIntoTwoDecimal(
            (subTotal * percentage) / 100
          );
          res.value = discount;
          discounts = res.value;
        } else {
          const percentage = parseFloat(res.percent);
          totalTaxPercentage.push(percentage);
          // to do need to fix calculation
          const actualAmount = subTotal - discounts;
          const tax = this.convertIntoTwoDecimal(
            (actualAmount * percentage) / 100
          );
          res.value = tax;
        }
      });

      this.totalAmount = this.convertIntoTwoDecimal(0);
      this.totalTaxes = totalTaxPercentage.map((a) => a);

      const taxSum = this.totalTaxes.reduce((a, b) => a + b, 0);
      const corpDiscount = this.corpDiscount ? this.corpDiscount : 0;
      const discountedPrice = this.subTotal * (corpDiscount / 100);
      const taxAmount = (this.subTotal - discountedPrice) * (taxSum / 100);
      const total_amount = this.subTotal - discountedPrice + taxAmount;

      this.totalAmount = this.convertIntoTwoDecimal(total_amount);

      // this.subTotalFields.forEach((res) => {
      //   // Subtract the corporate discount
      //   if (res.name === "Corporate Discount") {
      //     this.totalAmount = this.totalAmount - +res.value;
      //   }
      //   // add all others value
      //   else {
      //     this.totalAmount += +res.value;
      //   }
      // });

      // this.totalAmount = this.convertIntoTwoDecimal(this.totalAmount);
    },
    convertIntoTwoDecimal(value) {
      return Number(value).toFixed(this.currencyDecimalPlace || 2);
    },

    async onClientSelected(e) {
      try {
        const clientId = e.id;
        await this.fetchClientDetailAndSetCurrency(clientId);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchClientDetailAndSetCurrency(clientId) {
      try {
        const selectedClientDetail = await getSelectedClientDetail(clientId);
        const currencyId = selectedClientDetail.data.currency_id;
        if(currencyId) {
          this.setCurrencyDetail(currencyId);
        } else {
          this.$toast.error('Unable to fetch the currency detail')
        }
      } catch (err) {
        this.$toast.error(
          err?.response?.data?.detail || "Unable to fetch the client detail!"
        );
      }
    },
    async setCurrencyDetail(currencyId) {
      try {
        const currencyDetail = await getCurrencyDetail(currencyId);
        this.currencySymbol = currencyDetail?.data?.currency_symbol;
        this.currencyDecimalPlace = currencyDetail?.data?.decimal_place;
      } catch (err) {
        this.$toast.error(
          err?.response?.data?.detail || "Unable to fetch the currency detail!"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.removeArrowKey {
  -moz-appearance: textfield;
}

.removeArrowKey::-webkit-outer-spin-button,
.removeArrowKey::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.orderDate {
  height: 37px;
  border: 1px solid var(--theme-color-box-stroke);
  border-radius: .3rem;
}
::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
  .disableDropdown {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>

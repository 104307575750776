import axios from "@/axios";

export async function getSalesOrders(params) {
  const url = `/tenant-sales?${params}`;
  return await axios.get(url);
}

export async function getClientsList(tenantId) {
  const url = `tenant/${tenantId}/client`;
  return await axios.get(url);
}

export async function getClientTax(tenantId) {
  const url = `/tax/tax-client/${tenantId}`;
  return await axios.get(url);
}

export async function createOrder(payload) {
  const url = "/tenant-sales";
  return await axios.post(url, payload);
}

export async function getClientCreditPacks(clientId) {
  const url = `/tenant-sales/client/${clientId}/credit-packs`;
  return await axios.get(url);
}

export async function getOrderNumber(clientId) {
  const url = `/tenant-salesclient/${clientId}/order-number`;
  return await axios.get(url);
}

export async function getOrderDetails(orderId) {
  const url = `/tenant-sales/${orderId}/details`;
  return await axios.get(url);
}

export async function getOrderCreditPacks(orderId) {
  const url = `/tenant-sales/${orderId}/credit-packs`;
  return await axios.get(url);
}

export async function orderStatus(payload, orderId) {
  const url = `/tenant-sales/${orderId}/status`;
  return await axios.post(url, payload);
}

export async function updateOrder(payload, orderId) {
  const url = `/tenant-sales/${orderId}`;
  return await axios.put(url, payload);
}

export async function getClientDetails(clientId) {
  const url = `/client/${clientId}/new`;
  return await axios.get(url);
}

export async function getCurrencyDetail(currency_id) {
  const url = `/currency/${currency_id}`;
  return await axios.get(url);
}

export async function getSelectedClientDetail(client_id) {
  const url = `/client/${client_id}`;
  return await axios.get(url);
}
